<template>
	<div></div>
</template>

<script>
import Welcome from '@/components/login/Welcome';
import mixinsAlert from '@/components/common/mixinsAlert';
import { SIGNUP_MODAL_STYLE } from '@/utils/constants';

export default {
	name: 'SignUpSuccess',
	mixins: [mixinsAlert],
	created() {
		this.$router.push('/login');
		this.$modal.show(
			Welcome,
			{ name: this.name },
			Object.assign({}, SIGNUP_MODAL_STYLE, { name: 'welcome', clickToClose: false }),
		);
	},
	data() {
		return {
			name: this.$route.query.name,
		};
	},
	methods: {},
};
</script>

<style scoped>
@import '../../assets/css/common.css';
@import '../../assets/css/login.css';
</style>
