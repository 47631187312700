<template>
	<div>
		<div class="header">
			<button class="icon" @click="checkCloseMethod">
				<i class="ic-24 ic-close"></i>
			</button>
		</div>

		<div class="contents-wrap">
			<p class="heading-3-m text-center">회원가입을 환영합니다,</p>
			<p class="heading-3-b text-center mb-24">{{ name }} 님!</p>
			<p class="body-2-r text-center">
				성공적으로 회원가입을 완료했습니다. <br />
				아래 로그인 버튼을 눌러 다우오피스 고객포털을 사용해보세요!
			</p>
		</div>
		<!-- 확인 -->
		<div class="btn-area">
			<button class="solid full" @click="checkCloseMethod">로그인</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Welcome',
	props: {
		name: String,
	},
	methods: {
		checkCloseMethod() {
			this.$emit('close');
		},
	},
};
</script>

<style scoped></style>
